import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  OnInit,
  signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import {
  filter, firstValueFrom, Observable, take 
} from 'rxjs';
import { FLIP_AROUND_ANIMATION } from '~animations/flip-around.animation';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { SignalrService } from '~signalr/services/signalr/signalr.service';

import { SidebarService } from '../../services/sidebar.service';
import { AppState } from '../../states/app/app.state';
import { SidebarState } from '../../states/sidebar/sidebar.state';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: [ './topbar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ FLIP_AROUND_ANIMATION ],
  standalone: false
})
export class TopbarComponent implements OnInit {

  private appState = inject(AppState);
  private cdr = inject(ChangeDetectorRef);
  private facilityState = inject(FacilityState);
  private injector = inject(Injector);
  private networkState = inject(NetworkState);
  private router =  inject(Router);
  private sidebarSvc = inject(SidebarService);
  private sidebarState = inject(SidebarState);
  private signalR = inject(SignalrService);
  private destroyRef = inject(DestroyRef);

  activePermitType$ = this.appState.activePermitType$;
  childPageTitle$ = this.appState.childPageTitle$;
  collapsed$ = this.sidebarState.collapsed$;
  facilityName$ = this.appState.facilityName$;
  isOffline$: Observable<boolean> = this.networkState.isOffline$;
  networkOnline$: Observable<boolean> = this.networkState.networkOnline$;
  pageTitle$ = this.appState.pageTitle$;
  inPermitEditPage = signal(false);
  selectedId: any;
  showSettings = false;
  $serverConnectionStatus = toSignal(this.appState.serverConnectionStatus$, { injector: this.injector });
  serverConnectionStatusIconEnabled = false;

  get isActiveDirectory() {
    return this.appState.get('user')?.isActiveDirectoryUser;
  }

  get infoUrl() {
    return this.facilityState.get('generalSettings')?.infoUrl;
  }

  get offlineModeEnabled(): boolean {
    return !!this.facilityState.get('moduleSettings')?.OfflineMode;
  }

  get notificationsEnabled() {
    return this.facilityState.get('moduleSettings')?.Notifications;
  }

  ngOnInit(): void {
    this.serverConnectionStatusIconEnabled = (localStorage.getItem('serverConnectionStatusIconEnabled')?.toLowerCase() === 'true');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(event => this.inPermitEditPage.set(event.url.includes('/Permit/Edit')));
  }

  async toggleOfflineMode(): Promise<void> {

    let appOnline = await firstValueFrom(this.networkState.appOnline$.pipe(take(1)));

    this.networkState.set('appOnline', !appOnline);

    this.cdr.markForCheck();

    if (appOnline) {
      this.router.navigateByUrl('/offline/dashboard');
    }
  }

  toggleSidebar(): void {
    this.sidebarSvc.toggleSidebar();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  }

  async broadcastButton(obj: any, event: MouseEvent): Promise<void> {
    if (!event.shiftKey) {
      await this.signalR.consoleMessageToAll({ message: 'Server Communication broadcast message test! (WebSockets)' });
    }
  }

}
