@use 'variables' as *;

@use '@angular/material' as mat;

app-sidebar {
  @include mat.elevation(3);
  height: 100%;
  width: 100%;
  max-width: 250px;

  &.mobile {
    position: absolute;
    z-index: 9999;
  }

  #stil-sidebar {
    z-index: 9999;
    max-width: 250px !important;
    max-height: calc(100vh - 50px);
    border-right: 1px solid rgba(0,0,0, .23);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white-100;

    &.collapsed {
      border: none !important;
    }

    .nav-block {
      background-color: rgba($white-100, 0.6);
      border-bottom: $border-thin;
      color: $text-color;
      outline: none;
      min-width: 250px;

      &:hover {
        background-color: $white-200;
        cursor: pointer;
      }

      &.selected {
        background-color: $white-300;
      }

      .nav-block-title {
        font-size: 14px;
        user-select: none;
      }
    }
  }
}
