import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { OfflinePermitIndexService } from '../offline-permit-index.service';

@Injectable({
  providedIn: 'root'
})
export class OfflinePermitIndexResolver  {
  private offlinePermitIndexSvc = inject(OfflinePermitIndexService);
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const { permitType } = route.params;

    return await this.offlinePermitIndexSvc.getIndexPermitsAndColumns(permitType);
  }
}
