import {
  ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, input, Output 
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { PermissionsState } from '~permissions/state/permissions.state';
import { Dictionary } from '~shared/models/dictionary';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class NavGroupComponent {

  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);
  private permissionsState = inject(PermissionsState);

  group = input<Dictionary<any>>();

  unsyncedOfflinePermitTypes = input<string[]>([]);

  @Input() isActive: boolean;

  @Input() isOpen: boolean;

  @Output() groupClick = new EventEmitter<Dictionary<any>>();

  offlineModeEnabled$ = combineLatest([ this.facilityState.moduleSettings$, this.networkState.isOffline$ ]).pipe(
    map(([ moduleSettings, isOffline ]) => !!moduleSettings?.OfflineMode && isOffline)
  );

  offlineModeEnabled = toSignal(this.offlineModeEnabled$);

  showOfflinePermitIndex = computed(() => {
    const unsyncedOfflinePermitTypes = this.unsyncedOfflinePermitTypes();
    const group = this.group();
    const offlineEnabled = this.offlineModeEnabled();

    return offlineEnabled || unsyncedOfflinePermitTypes.includes(group?.itemType);
  });

  links = computed(() => {
    const group = this.group();
    const offlineEnabled = this.offlineModeEnabled();

    let links = group?.links;

    const navPermissions = this.permissionsState.get('leftNav');
    const permitPermissions = navPermissions?.[group?.itemType]?.PermitCreateOffline;

    if (offlineEnabled) {
      links = filter(links, link => {
        const name = link.name.toLowerCase();
        return name.includes('create') && !name.includes('standard');
      });

      if (!permitPermissions) {
        links = reject(links, ({ name }) => name.toLowerCase().includes('create'));
      }
    }

    return links;
  });
}
