import { NgModule } from '@angular/core';
import {
  PreloadAllModules, RouterModule, Routes 
} from '@angular/router';
import { AnnouncementDashletComponent } from '~announcements/components/announcement-dashlet/announcement-dashlet.component';
import { LoginPageResolve } from '~auth/resolves/login-page/login-page.resolve';
import { AppResolve } from '~core/resolves/app.resolve';
import { ActivePermitGroupResolve } from '~permit/resolves/active-permit-group/active-permit-group.resolve';
import { translationLanguagesResolver } from '~translations/pages/translations/resolvers/translation-languages.resolver';
import { translationsDataResolver } from '~translations/pages/translations/resolvers/translations-data.resolver';

import { AuthGuard } from './auth/guards/auth.guard';
import { LoginGuard } from './auth/guards/login.guard';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { DashboardGuard } from './dashboards/guards/dashboard.guard';
import { DefaultRedirectComponent } from './default-redirect/default-redirect.component';
import { offlineDashboardResolver } from './offline-dashboard/resolve/offline-dashboard.resolver';
import { OfflinePermitIndexResolver } from './offline-permit-index/resolve/offline-permit-index.resolver';
import { reportFilesResolver } from './report/report-files/resolve/report-files.resolver';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: '',
        component: DefaultRedirectComponent,
        pathMatch: 'full'
      },
      {
        path: 'announcements',
        component: AnnouncementDashletComponent
      },
      {
        path: 'dashboards',
        canActivate: [ DashboardGuard ],
        loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: 'offline/dashboard',
        loadComponent: () => import('./offline-dashboard/offline-dashboard.component').then(m => m.OfflineDashboardComponent),
        resolve: {
          permitTypesData: offlineDashboardResolver
        }
      },
      {
        path: 'offline/permit/:permitType/index',
        loadComponent: () => import('./offline-permit-index/offline-permit-index.component').then(m => m.OfflinePermitIndexComponent),
        resolve: {
          activePermitGroup: ActivePermitGroupResolve,
          indexData: OfflinePermitIndexResolver
        }
      },
      {
        path: 'Permit',
        loadChildren: () => import('./permit/permit.module').then(m => m.PermitModule)
      },
      {
        path: 'assets',
        loadChildren: () => import('./asset-admin/asset-admin-route-config').then(x => x.ASSET_ADMIN_ROUTE_CONFIG)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.routes').then(m => m.AdminRoutes)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'question-manager',
        loadChildren: () => import('./question-manager/question-manager.module').then(m => m.QuestionManagerModule)
      },
      {
        path: 'announcement-manager',
        loadChildren: () => import('./announcement-manager/announcement-manager.module').then(m => m.AnnouncementManagerModule)
      },
      {
        path: 'security',
        loadChildren: () => import('./security/security.module').then(m => m.SecurityModule)
      },
      {
        path: 'masterlist',
        loadChildren: () => import('./masterlist/masterlist.module').then(m => m.MasterlistModule)
      },
      {
        path: 'notifications-manager',
        loadChildren: () => import('./notifications/notifications.routes').then(r => r.NotificationRoutes)
      },
      {
        path: 'report/report-settings',
        loadComponent: () => import('./report/report-settings/report-settings.component').then(m => m.ReportSettingsComponent)
      },
      {
        path: 'report/report-files',
        resolve: {
          reportFiles: reportFilesResolver
        },
        loadComponent: () => import('./report/report-files/report-files.component').then(m => m.ReportFilesComponent)
      },
      {
        path: 'translations',
        loadComponent: () => import('~translations/pages/translations/translations.component').then(m => m.TranslationsComponent),
        resolve: {
          languages: translationLanguagesResolver,
          translationsData: translationsDataResolver
        }
      },
      {
        path: 'printlist',
        loadChildren: () => import('./printlist/printlist.module').then(m => m.PrintlistModule)
      },
      {
        path: 'facility-settings',
        loadChildren: () => import('./facility-settings/facility-settings.module').then(m => m.FacilitySettingsModule)
      },
      {
        path: 'SafeTKonnect',
        loadChildren: () => import('./safetkonnect/safetkonnect.routes').then(r => r.SafetkonnectRoutes)
      },
      {
        path: 'index',
        loadChildren: () => import('./permit-index-v2/permit-index-v2.module').then(m => m.PermitIndexV2Module)
      }
    ],
    resolve: {
      appState: AppResolve
    },
    runGuardsAndResolvers: 'pathParamsChange'
  },
  {
    path: 'login',
    canActivate: [ LoginGuard ],
    component: LoginPageComponent,
    resolve: {
      loginPageData: LoginPageResolve
    }
  },
  {
    path: 'announcement-manager',
    loadChildren: () => import('./announcement-manager/announcement-manager.module').then(m => m.AnnouncementManagerModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
