import {
  Component, DestroyRef, inject, OnInit 
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';

@Component({
  template: '',
  standalone: true
})
export class DefaultRedirectComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);
  private router = inject(Router);

  ngOnInit(): void {
    this.networkState.isOnline$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isOnline => {
        if (isOnline) {
          if (this.facilityState.get('moduleSettings')?.CustomDashboards) {
            return this.router.navigate([ '/dashboards' ]);
          }

          return this.router.navigate([ '/announcements' ]);
        }

        return this.router.navigate([ '/announcements' ]);
      });
  }
}
