import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot 
} from '@angular/router';
import { FacilityState } from '~core/states/facility/facility.state';

import { OfflinePermitIndexService } from '../../offline-permit-index/offline-permit-index.service';

export const offlineDashboardResolver: ResolveFn<any> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> => {
  const facilityState = inject(FacilityState);
  const offlinePermitIndexSvc = inject(OfflinePermitIndexService);
  const offlinePermitTypes = facilityState.get('offlinePermitTypes');

  const permitTypesData = [];

  for (const permitType of offlinePermitTypes) {
    const { permits = [], columns = [] } = await offlinePermitIndexSvc.getIndexPermitsAndColumns(permitType);

    if (permits.length) {
      permitTypesData.push({
        permitType,
        permits,
        columns
      });
    }
  }

  return permitTypesData;
};
